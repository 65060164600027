/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Global, css } from '@emotion/core'
import { PostListing, Wrapper, DefaultHero, SEO } from '../components'
import website from '../../config/website'
import { LocaleContext } from '../components/Layout'

const CatWrapper = Wrapper.withComponent('main')

const Blog = ({
  pageContext: { locale },
  data: {
    posts: { edges, totalCount },
  },
  location,
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <Global />
      <SEO title={`${i18n.pages.blog.title} | ${i18n.defaultTitleAlt}`}
      pathname={location.pathname}
      locale={locale}
      pageType={i18n.pages.home.name}
      />
      <DefaultHero>
        <Wrapper>
          <h1>{i18n.pages.blog.title}</h1>
        </Wrapper>
      </DefaultHero>
      <CatWrapper id={website.skipNavId}>
        <PostListing posts={edges} />
      </CatWrapper>
    </>
  )
}

export default Blog

Blog.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    posts: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query PostsPage($locale: String!) {
    posts: allPrismicPost(
      sort: { fields: [data___date], order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      totalCount
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
